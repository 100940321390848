





































































































































































































//@import url(); 引入公共css类
.image-button {
  background: linear-gradient(to right, #2840d4, #399ff7);
  color: white;
  width: 180px;
  height: 50px;
  outline: none;
}
.image-button:focus{
  outline: none;
  box-shadow: none;
  outline-color: transparent;
}
.el-divider--horizontal {
  margin: 0 0;
  width: 90%;
  height: 1px;
}

.bg-purple {
  background: white;
}

.grid-content {
  min-height: 50px;
}

.title {
  height: 160px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  background: url("../assets/images/bossnews/Group 2175.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 500px;
  position: relative;
  z-index: 1;
}
